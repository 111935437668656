import Vue from 'vue';

export const state = Vue.observable({
	api: 'https://anketapi.minzdrav.gov.ru/',
	anketTypeName: 'ambulator',
	dependencyQuestions: [],
	search: '',
	Regions: [],
	anket: [],
	anketFull: [],
	moItems: [],
	anketCode: null,
	anketType: '',
	title: '',
	disabledState: false,
	number: 0,
	mo: {}
});
