import { state } from './state';

export const gettersLocal = {
	disabled: () => state.disabledState,
	anketRoutType: () => state.anketType,
	getSearch: () => state.search,
	getTitle: () => state.title,
	getMoArr: () => state.moItems,
	answerNumber: () => state.number,
	getAnketCode: () => state.anketCode,
	dataMO: () => state.mo,
	dataAnket: () => state.anket,
	anketFull: () => state.anketFull,
	regionsArr: () => state.Regions,
	currentRegion: () => state.Region
};
