// Если нам надо обработать данные используем мутации
import { mutations } from '@/store/store';
import { mutationsLocal } from './mutations';
import { state } from './state';

export const actions = {
	getRegions(text) {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`${state.api}Region/GetRegions?q=${text}`)
				.then((res) => {
					resolve(res);
					mutationsLocal.setRegions(res.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getRegionByOldId(id) {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`${state.api}Region/GetRegionByOldId?oldId=${id}`)
				.then((res) => {
					mutations.setRegion(res.data);
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getMOList(data) {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`${state.api}MO/GetMedicalOrgs?regionId=${data.id}&q=${data.text}`)
				.then((res) => {
					mutationsLocal.setMoItems(res.data);
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getMObyOldId(id) {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`${state.api}MO/GetMOByOldId?oldId=${id}`)
				.then((res) => {
					mutationsLocal.setMO(res.data);
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getMOById(id) {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`${state.api}MO/GetMOById?moId=${id}`)
				.then((res) => {
					mutationsLocal.setMO(res.data);
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getAnketaById() {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`${state.api}Anketa/GetAnketaById?anketaId=${state.anketType}`)
				.then((res) => {
					mutationsLocal.setAnket(res.data);
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	postAnket(anketa) {
		let anketType = '';
		switch (anketa.type) {
			case 'ambulator':
				anketType = 'Anketa/PostAmbulatorAnswer';
				break;
			case 'stacionar':
				anketType = 'Anketa/PostStacionarAnswer';
				break;
			case 'healthResort':
				anketType = 'Anketa/PostSanatoriaAnswer';
				break;
			case 'psychiatric':
				anketType = 'Anketa/PostPsychiatricAnswer';
				break;
			default:
				anketType = '';
		}
		return new Promise((resolve, reject) => {
			this.$http
				.post(state.api + anketType, anketa.resulte)
				.then((res) => {
					mutationsLocal.anketCode(res.data);
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
};
