<template>
	<div class="mb-6 sm:mb-0">
		<div v-if="region !== null" class="item mb-6">
			<p class="label-lg">Регион</p>
			<p class="value-lg">{{ region.name }}</p>
		</div>
		<div class="item">
			<p class="label-lg">
				{{
					region !== null && region.fedDistrictCode !== null && region.wikiRegionCode === null
						? 'Медицинская организация, учредителем которой является Российская Федерация'
						: 'Медицинская организация'
				}}
			</p>
			<Autocomplite type="autocomplite" placeholder="Пожалуйста, выберите медицинскую организацию" />
			<div class="item mt-6" v-if="dataMO.departmentBelongName">
				<p class="label-lg">Функции и полномочия учредителя от имени Российской Федерации осуществляет:</p>
				<p class="value-lg">{{ dataMO.departmentBelongName }}</p>
			</div>
			<div class="item mt-6" v-if="dataMO.webSiteAddress">
				<p class="label-lg">Официальный сайт</p>
				<p class="value-lg">{{ dataMO.webSiteAddress }}</p>
			</div>
			<div class="item mt-6" v-if="dataMO.address">
				<p class="label-lg" v-if="dataMO.address">Фактический адрес</p>
				<p class="value-lg" v-if="dataMO.address">{{ dataMO.address }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import { getters } from '@/store/store';
import { gettersLocal } from '@/views/Questionnaire/store/getters';
import { actions } from '@/views/Questionnaire/store/actions';
import Autocomplite from '@/components/Autocomplite.vue';

export default {
	name: 'ChangeRegion',
	components: { Autocomplite },
	data() {
		return {};
	},
	computed: {
		...getters,
		...gettersLocal
	},
	methods: {
		...actions
	},
	mounted() {}
};
</script>

<style>
.label-lg {
	@apply text-sm pb-2.5 font-bold;
	color: #a8b1ce;
}

.value-lg {
	@apply font-medium text-base;
}
</style>
